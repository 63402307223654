<template>
  <section>
    <nav class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Zipscreenanlage <br />
    </nav>
    <article class="container mx-auto pl-2 pt-2 lg:p-32">
      <h1 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Eurosun - Zipscreen
      </h1>
      <div class="grid grid-cols-2">
        <div class="col-span-full md:col-span-1">
          <img
            src="../assets/img/eurosun/zipscreen/eurosun-zip-screen.webp"
            alt="zip-screen eurosun"
            class="md:h-96 w-full"
          />
        </div>

        <div class="col-span-full md:col-span-1">
          <h2
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            ZIP-Screen
          </h2>
          <div class="px-5 w-full md:pl-10 md:pb-3">
            Hochwertige ZIP-Screens von EUROSUN sind die smarte Lösung für eine
            moderne Beschattung: Die textilen Anlagen bieten nicht nur einen
            windstabilen Sonnenschutz, sie lassen sich auch als attraktive
            Gestaltungselemente für Ihre Gebäudefassade einsetzen. Je nach Farbe
            und Transparenzgrad modulieren sie die Raumstimmung.
            <h3 class="py-3 text-2xl">Vorteile:</h3>

            <i class="fas fa-caret-right"></i> Textiler Schutz vor Blendeffekten
            und Überhitzung<br />
            <i class="fas fa-caret-right"></i> Durchsicht auf die Umgebung – je
            nach Tuch<br />
            <i class="fas fa-caret-right"></i> Hohe Windstabilität durch
            Schienenführung<br />
            <i class="fas fa-caret-right"></i> Vielfältige Möglichkeiten
            farbiger Fassadengestaltung<br />
            <i class="fas fa-caret-right"></i> Individuelles wohnliches Flair
            dank großer Tuchauswahl<br />
            <i class="fas fa-caret-right"></i> Natürliche Raumatmosphäre durch
            Einfall von Tageslicht<br />
          </div>
        </div>
      </div>
      <div class="p-4">
        <div class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</div>
        <tabs :mode="mode">
          <tab title="Blendenformen">
            <p class="text-lg">
              Die Stärken der EUROSUN Blenden liegen in besten Materialien,
              sorgfältiger Verarbeitung und breit gefächerten
              Einsatzmöglichkeiten. Sie sind in Größen von 85 bis 125 mm
              erhältlich.
            </p>
            <div class="flex flex-col sm:flex-row">
              <div>
                <img
                  src="../assets/img/eurosun/zipscreen/kasten-45grad-125.webp"
                  alt="eurosun zipscreen kasten-45-grad"
                />
              </div>
              <div>
                <img
                  src="../assets/img/eurosun/zipscreen/kasten-90-s.webp"
                  alt="eurosun zipscreen kasten-90"
                />
              </div>
              <div>
                <img
                  src="../assets/img/eurosun/zipscreen/kasten-hc-125rund.webp"
                  alt="eurosun zipscreen hc-125 rund"
                />
              </div>
              <div>
                <img
                  src="../assets/img/eurosun/zipscreen/vsz90grad-putz-3.webp"
                  alt="eurosun zipscreen vsz90grad"
                />
              </div>
            </div>
          </tab>
          <tab title="Extras">
            <p class="text-lg">
              Eurosun - Außenraffstore ist in verschiedenen Farben und
              Ausführungen erhältich. <br />
              Besuchen Sie unsere Ausstellung oder lassen Sie sich beraten.
            </p>
          </tab>
        </tabs>
      </div>
    </article>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";

export default {
  components: {
    Tab,
    Tabs,
  },
  methods: {},
};
</script>
<style>
button:focus {
  color: #f27405;
}
button::-moz-focus-inner {
  border: 0;
}
</style>
